import React, { useEffect, useState } from "react";
import AdminNav from "../Components/AdminNav";
import Footer from "../Components/Footer";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import bibkContract from "../Contracts/bibk.json";
import bigInt from "big-integer";
import { gql, useQuery } from "@apollo/client";
import { formatEther, parseEther } from "viem";
import toast, { Toaster } from "react-hot-toast";

const BibkStaking = () => {
  const MyBackgroundImage = "/img/bg1.png";
  const account = useAccount();
  const [apy, setApy] = useState(0);
  const [showStakeModal, setShowStakeModal] = useState(false);
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [totalBibkEarned, setTotalBibkEarned] = useState(0);
  const [bibkEarned, setBibkEarned] = useState(0);
  const [amount, setAmount] = useState(0);
  const [unstakeAmount, setUnstakeAmount] = useState(0);
  const [bibkLockedValue, setBibkLockedValue] = useState(0);
  const [userBibkBalance, setUserBibkBalance] = useState(0);
  const [buyValue, setBuyValue] = useState(0);
  const [transactionHash, setTransactionHash] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [btnIsLoading, setBtnIsLoading] = useState(false);

  const TOTALBIBKEARNED_QUERY = gql`
    query TotalBibkEarned($address: ID!) {
      user(id: $address) {
        rewardsByBibkStaking
      }
    }
  `;

  const fetchTotalBibkEarned = useQuery(TOTALBIBKEARNED_QUERY, {
    variables: {
      address: account.isConnected ? account.address.toLowerCase() : "",
    },
  });

  useEffect(() => {
    if (!fetchTotalBibkEarned.loading && fetchTotalBibkEarned.data.user) {
      setTotalBibkEarned(
        parseFloat(
          formatEther(fetchTotalBibkEarned.data.user.rewardsByBibkStaking)
        ).toFixed(4)
      );
    }
  }, [fetchTotalBibkEarned]);

  const handleStakeModal = () => {
    if (showStakeModal) {
      setShowStakeModal(false);
    } else {
      setShowStakeModal(true);
    }
  };

  const handleUnstakeModal = () => {
    if (showUnstakeModal) {
      setShowUnstakeModal(false);
    } else {
      setShowUnstakeModal(true);
    }
  };

  useEffect(() => {
    if (showStakeModal || showUnstakeModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showStakeModal, showUnstakeModal]);

  const balanceOfFunc = useContractRead({
    address: process.env.REACT_APP_BIBK_CONTRACT,
    abi: bibkContract,
    functionName: "balanceOf",
    args: [account.address],
  });

  useEffect(() => {
    if (balanceOfFunc.isFetched && balanceOfFunc.data) {
      setUserBibkBalance(
        parseFloat(formatEther(balanceOfFunc.data)).toFixed(4)
      );
    }
  }, [balanceOfFunc]);

  const handleStakeAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleUnstakeAmountChange = (event) => {
    setUnstakeAmount(event.target.value);
  };

  const buyPriceFunction = useContractRead({
    address: process.env.REACT_APP_BIBK_CONTRACT,
    abi: bibkContract,
    functionName: "buyPrice",
  });

  useEffect(() => {
    if (buyPriceFunction.isFetched && buyPriceFunction.data) {
      setBuyValue(parseFloat(formatEther(buyPriceFunction.data)));
    }
  }, [buyPriceFunction]);

  const { config: stakeConfig, refetch: stakeRefetch } =
    usePrepareContractWrite({
      address: process.env.REACT_APP_BIBK_CONTRACT,
      abi: bibkContract,
      functionName: "stake",
      args: [parseEther(amount.toString())],
    });

  const stakeFunction = useContractWrite({
    ...stakeConfig,
    onSuccess(data) {
      setToastMessage("Bibk staked successfully");
      setTransactionHash(data.hash);
    },
    onError(error) {
      setToastMessage(error.message);
    },
  });

  const handleStake = async () => {
    if (parseFloat(amount) > userBibkBalance) {
      window.alert("amount exceed");
    } else {
      setBtnIsLoading(true);
      await stakeRefetch();
      stakeFunction.write();
    }
  };

  const { config: unstakeConfig, refetch: unstakeRefetch } =
    usePrepareContractWrite({
      address: process.env.REACT_APP_BIBK_CONTRACT,
      abi: bibkContract,
      functionName: "unstake",
      args: [parseEther(unstakeAmount.toString())],
    });

  const unstakeFunction = useContractWrite({
    ...unstakeConfig,
    onSuccess(data) {
      setToastMessage("Bibk unstaked successfully");
      setTransactionHash(data.hash);
    },
    onError(error) {
      setToastMessage(error.message);
    },
  });

  const handleUnstake = async () => {
    if (parseFloat(unstakeAmount) > bibkLockedValue) {
      window.alert("amount exceed");
    } else {
      setBtnIsLoading(true);
      await unstakeRefetch();
      unstakeFunction.write();
    }
  };

  const transactionConfirmHook = useWaitForTransaction({
    hash: transactionHash,
  });

  const resApy = useContractRead({
    address: process.env.REACT_APP_BIBK_CONTRACT,
    abi: bibkContract,
    functionName: "apy",
  });

  useEffect(() => {
    if (resApy.isSuccess) {
      setApy(bigInt(resApy.data).toString());
    }
  }, [resApy.isSuccess]);

  const stakedFunction = useContractRead({
    address: process.env.REACT_APP_BIBK_CONTRACT,
    abi: bibkContract,
    functionName: "staked",
    args: [account.address],
  });

  useEffect(() => {
    if (stakedFunction.isSuccess) {
      setBibkLockedValue(parseFloat(formatEther(stakedFunction.data)));
    }
  }, [stakedFunction.isSuccess, stakedFunction.isRefetching]);

  const getEarnedRewardsFunc = useContractRead({
    address: process.env.REACT_APP_BIBK_CONTRACT,
    abi: bibkContract,
    functionName: "getEarnedRewards",
    account: account.address,
  });

  useEffect(() => {
    if (getEarnedRewardsFunc.isSuccess) {
      setBibkEarned(
        parseFloat(formatEther(getEarnedRewardsFunc.data)).toFixed(4)
      );
    }
  }, [getEarnedRewardsFunc.isSuccess, getEarnedRewardsFunc.isRefetching]);

  useEffect(() => {
    if (transactionConfirmHook.isSuccess) {
      toast.success(toastMessage, { duration: 5000 });
      fetchTotalBibkEarned.refetch();
      stakedFunction.refetch();
      stakeFunction.reset();
      unstakeFunction.reset();
      getEarnedRewardsFunc.refetch();
      balanceOfFunc.refetch();
      setShowStakeModal(false);
      setShowUnstakeModal(false);
      setBtnIsLoading(false);
    } else if (transactionConfirmHook.isError) {
      toast.error(toastMessage, { duration: 10000 });
      setShowStakeModal(false);
      setShowUnstakeModal(false);
      setBtnIsLoading(false);
    }
  }, [transactionConfirmHook.isSuccess, transactionConfirmHook.isError]);

  return (
    <>
      <AdminNav />
      <div className="xl:py-10 2xl:py-20 py-10 m-auto w-[90%] lg:flex  flex-col justify-center">
        <div className="_0shine space-y-5 text-justify rounded-xl p-5 xl:p-12 xl:w-[85%] 2xl:w-[80%] m-auto">
          <div className="xl:flex space-y-4 xl:space-y-0 items-center justify-between ">
            <div className="">
              <h1 className="text-3xl xl:text-2xl 2xl:text-4xl font-bold">
                BIBK Staking
              </h1>
              <p className="text-[#92D940] xl:text-[10px] 2xl:text-sm">
                Earn BIBK by staking BIBK
              </p>
            </div>
            <div className="">
              <p className="text-white xl:text-[12px] 2xl:text-sm">
                BIBK/ETH: {buyValue > 0 ? Math.round(1 / buyValue) : "0000"}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center py-10 xl:py-0 xl:space-y-0 space-y-10 ">
            <div className="xl:flex justify-between items-center gap-10   xl:space-y-0 space-y-10">
              <div className="">
                <div className="space-y-4 text-center">
                  <div className="">
                    <p className="text-white italic">Earn {apy}% APY on BIBK</p>
                  </div>
                  <div className="">
                    <p className="text-white italic">Total BIBK Locked:</p>
                    <p className="text-[#92D940] text-sm ">{bibkLockedValue}</p>
                  </div>
                  <div className="">
                    <p className="text-white italic">Total BIBK Earned:</p>
                    <p className="text-[#92D940] text-sm ">{totalBibkEarned}</p>
                  </div>
                  <div className="">
                    <p className="text-white italic">
                      Total Bibk to be rewards:
                    </p>
                    <p className="text-[#92D940] text-sm ">{bibkEarned}</p>
                  </div>
                </div>
              </div>

              <div className="">
                <div
                  className="xl:w-[400px] h-[400px] w-[340px] md:w-[400px] "
                  style={{
                    backgroundImage: `url(${MyBackgroundImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="flex flex-col h-[400px] justify-center space-y-2">
                    <div className=" leading-tight text-center">
                      <h1 className="">
                        {" "}
                        <span className="text-5xl no text-[#92D940] ">
                          TVL:
                        </span>
                      </h1>
                    </div>
                    <div className="text-center">
                      <h1 className="text-5xl  no">{bibkLockedValue}</h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="space-y-5 text-center">
                  <div className="">
                    <button
                      onClick={handleStakeModal}
                      className="bg-[#92D940] hover:bg-[#71a832]  text-black font-semibold text-sm rounded-full px-8 py-2"
                    >
                      Stake BIBK
                    </button>
                  </div>
                  <div className="text-white font-bold space-y-2">
                    <button
                      onClick={handleUnstakeModal}
                      className="bg-white hover:bg-gray-100  text-black font-semibold text-sm rounded-full px-8 py-2"
                    >
                      Unstake BIBK
                    </button>
                  </div>
                </div>
                <div className="flex items-center gap-5 justify-center">
                  <div className="">
                    <h1 className="text-white  text-sm">BIBK Earned:</h1>
                    <p className="text-[#92D940] ">{bibkEarned}</p>
                  </div>
                  <div className="py-10">
                    <div className="border w-1 h-8 bg-[#92D940] border-[#92D940]"></div>
                  </div>
                  <div className="">
                    <h1 className="text-white  text-sm">BIBK Staked:</h1>
                    <p className="text-[#92D940] ">{bibkLockedValue}</p>
                  </div>
                </div>
              </div>
              {showStakeModal && (
                <div style={{ backgroundColor: "transparent", zIndex: "1" }}>
                  <div className="overlay" onClick={handleStakeModal}></div>
                  <div
                    className="modal-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div>
                      <div className="mb-2" style={{ textAlign: "center" }}>
                        Stake BIBK
                      </div>
                      <div>BIBK Balance: {userBibkBalance}</div>
                      <div>
                        Amount:
                        <input
                          style={{ border: "2px solid gray", outline: "none" }}
                          placeholder="amount"
                          onChange={handleStakeAmountChange}
                          type="float"
                          value={amount}
                        />
                      </div>
                      {btnIsLoading ? (
                        <button
                          type="button"
                          className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                        >
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#1C64F2"
                            ></path>
                          </svg>
                          Loading...
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#92D940",
                            padding: "8px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleStake}
                        >
                          Stake
                        </button>
                      )}
                      {balanceOfFunc.isLoading && <div>Check Wallet</div>}
                      {stakeFunction.isSuccess && (
                        <div>
                          Transaction Hash:
                          <div
                            style={{
                              "max-width": "100%",
                              overflow: "hidden",
                              "word-wrap": "break-word",
                            }}
                          >
                            {stakeFunction.data.hash}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {showUnstakeModal && (
                <div style={{ backgroundColor: "transparent", zIndex: "1" }}>
                  <div className="overlay" onClick={handleUnstakeModal}></div>
                  <div
                    className="modal-container"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div>
                      <div className="mb-2" style={{ textAlign: "center" }}>
                        Unstake BIBK
                      </div>

                      <div>Staked BIBK :{bibkLockedValue}</div>

                      <div>
                        Amount:
                        <input
                          style={{ border: "2px solid gray", outline: "none" }}
                          placeholder="amount"
                          onChange={handleUnstakeAmountChange}
                          type="number"
                          value={unstakeAmount}
                        />
                      </div>
                      {btnIsLoading ? (
                        <button
                          type="button"
                          className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                        >
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#1C64F2"
                            ></path>
                          </svg>
                          Loading...
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "#92D940",
                            padding: "8px",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleUnstake}
                        >
                          Unstake
                        </button>
                      )}

                      {balanceOfFunc.isLoading && <div>Check Wallet</div>}
                      {unstakeFunction.isSuccess && (
                        <div>
                          Transaction Hash:
                          <div
                            style={{
                              "max-width": "100%",
                              overflow: "hidden",
                              "word-wrap": "break-word",
                            }}
                          >
                            {unstakeFunction.data.hash}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center py-10 xl:py-0 text-center ">
            <button className="bg-[#92D940] hover:bg-[#71a832] rounded-full text-black py-4 px-4 w-[50%] xl:w-[30%]">
              <p className="">Balances</p>
            </button>
          </div>
        </div>
      </div>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: "none",
          },
        }}
      />
    </>
  );
};

export default BibkStaking;
