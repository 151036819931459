import React, { createContext } from "react";
import Input from "../Components/Input";
import Navbar from "../Components/Navbar";
import UpcomingEvents from "./UpcomingEvents";
import Footer from "../Components/Footer";
import BibkPredict from "./BibkPredict";
import Hero from "./Hero";
import BinaryBlockProtocol from "./BinaryBlockProtocol";
import SmartContractAdress from "./SmartContractAdress";
import { Toaster } from "react-hot-toast";
import { useContractRead } from "wagmi";
import nftContractAbi from "../Contracts/nft.json";

export const HomepageContext = createContext(null);
const Home = () => {
  const totalSupplyFunc = useContractRead({
    address: process.env.REACT_APP_NFT_CONTRACT,
    abi: nftContractAbi,
    functionName: 'totalSupply',
  });

  return (
    <HomepageContext.Provider value={{totalSupplyFunc}}>
    <>
      <Navbar />
      <div id="top" className="py-10 m-auto xl:w-[90%] w-[95%]">
        <Hero />
        <BinaryBlockProtocol />
        <BibkPredict />
        <UpcomingEvents />
        <SmartContractAdress />
      </div>
      <Toaster
          toastOptions={{
            style: {
              maxWidth: "none",
            },
          }}
        />
    </>
    </HomepageContext.Provider>
  );
};

export default Home;
