import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";

import { useQuery } from "@apollo/client";
import NftList from "./NftList";
import { Web3Button } from "@web3modal/react";

const NFTListModal = ({
  visible,
  query,
  variables,
  title,
  transactionConfirmHook,
  onClose
}) => {
  const account = useAccount();
  const [nftArray, setNftArray] = useState([]);

  const fetchNftQuery = useQuery(query, {
    variables: variables,
  });

  useEffect(() => {
    if (!fetchNftQuery.loading && fetchNftQuery.data.user) {
      setNftArray(fetchNftQuery.data.user.nfts);
    } else {
      setNftArray([]);
    }
    
  }, [fetchNftQuery]);

  if (fetchNftQuery.error) return <pre>{fetchNftQuery.error.message}</pre>;

  if (!visible) return null;

  return (
    <div
      id="defaultModal"
      tabindex="-1"
      className="fixed inset-0 bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div className="relative max-w-full max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          
            <div>
              <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  {title}
                </h3>
                <button
                  onClick={() => onClose()}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {account.isConnected ? (
              <div className="p-6 space-y-6">
                {(transactionConfirmHook.isLoading || fetchNftQuery.loading) ? (
                  <div className="w-full text-center">
                    <div
                      class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  </div>
                ) : (
                  <NftList
                    nftArray={nftArray}
                  />
                )}
              </div>
              ) : (
                <div className="flex items-center justify-center p-4 border-b rounded-t">
                  <Web3Button
                    themeVariables={{
                      "--w3m-background-color": "var(--w3m-background-color)",
                    }}
                  >
                    Connect Wallet
                  </Web3Button>
                </div>
              )}
              {/* <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="defaultModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                <button data-modal-hide="defaultModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
            </div> */}
            </div>
        </div>
      </div>
    </div>
  );
};

export default NFTListModal;
