import React from "react";

const UpcomingEvents = () => {
  return (
    <div>
      <div className="my-20 ">
        <div className="_0shine py-12 ">
          <div className="space-y-3 m-auto w-[80%]">
            <h1 className="text-[24px] font-semibold 2xl:text-[38px] xl:text-[34px] py-3">
              Some of Our Upcoming Events
            </h1>
            <div className="md:space-y-3 space-y-5 md:text-md text-sm">
              <div className="">
                <h2 className="text-[#92D940] text-lg">BinaryBlock Protocol Deployment for BinaryBlock Genesis NFTs Staking and BIBK Staking</h2>
                <p className="">Date: To Be Announced (TBA)</p>
              </div>
              <div className="">
                <h2 className="text-[#92D940] text-lg">BinaryBlock Genesis NFTs Minting</h2>
                <p className="">Date: To Be Announced (TBA)</p>
              </div>
              <div className="">
                <h2 className="text-[#92D940] text-lg">BIBK Launch</h2>
                <p className="">Date: To Be Announced (TBA)</p>
              </div>
              <div className="">
                <h2 className="text-[#92D940] text-lg">Prediction Pools Deployment</h2>
                <p className="">Date: To Be Announced (TBA)</p>
              </div>
              <div className="">
                <h2 className="text-[#92D940] text-lg">Marketplace Deployment</h2>
                <p className="">Date: To Be Announced (TBA)</p>
              </div>
            </div>
            <div className="">
              <p className="xl:w-[70%]">Stay tuned for further updates and announcements regarding these exciting events in the BinaryBlock ecosystem!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvents;
