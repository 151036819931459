import React from 'react';
import Input from '../Components/Input';

const BinaryBlockProtocol = () => {
    return (
        <div>
            <div className=" m-auto ">
                <div className=" md:py-20 py-10 space-y-2 text-center ">
                    <h1 className="font-bold xl:font-semibold text-3xl xl:text-[50px]">
                        BinaryBlock Protocol
                    </h1>
                    <p className=" text-[#92D940] text-[20px]">
                        A web3 protocol for earning crypto on ethereum.
                    </p>
                </div>

                {/* Input */}
                <div className="md:w-[90%] m-auto">
                    <Input />
                </div>
            </div>
        </div>
    );
};

export default BinaryBlockProtocol;