import "./index.css";
import Footer from "./Components/Footer";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Team from "./Pages/Team";
import Faqs from "./Pages/Faqs";
import { useEffect } from "react";
import NotFound from "./Components/NotFound";
import Home from "./Pages/Home";
import BibkStaking from "./Pages/BibkStaking";
import GenesisStakingtaking from "./Pages/GenesisStaking";
import PredictionPool from "./Pages/PredictionPool";
import BibkMonthly from "./Pages/BibkMonthly";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { goerli, mainnet, polygon, sepolia } from "wagmi/chains";
import { infuraProvider } from 'wagmi/providers/infura'
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const chains = [mainnet, polygon, goerli, sepolia];
const projectId = "ae29e4ce3c12c633e855033b747dfe0d";

const { publicClient } = configureChains(chains, [infuraProvider({ apiKey: "901d0d9876834f78a79c65070b07a6cb" })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const client = new ApolloClient({
  uri: process.env.REACT_APP_SUBGRAPH_URL,
  cache: new InMemoryCache(),
});

function App() {
  const location = useLocation();
  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <ApolloProvider client={client}>
          <div className="flex min-h-[100vh] flex-col">
            {/* {<Navbar />} */}
            <div className="grow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/team" element={<Team />} />
                <Route path="/faqs" element={<Faqs />} />
                <Route path="/app" element={<GenesisStakingtaking />} />
                <Route path="/bibkstaking" element={<BibkStaking />} />
                <Route path="/PredictionPool" element={<PredictionPool />} />
                <Route path="/bibkmonthly" element={<BibkMonthly />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          <footer>
            <Footer />
          </footer>
        </ApolloProvider>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
