import React from 'react';

const EthPoolCard = () => {
    return (
        <div>
              <div className="">
              <div className="text-center my-5 mt-8">
                    <h2 className="text-xl xl:text-md">Current Position: <span className='text-[#92D940]'>09/90,000</span> </h2>
                </div>
                <div className="bg-[#8D8D8D4D] rounded-xl 2xl:p-10 p-8 xl:p-4 text-cente xl:text-[10px] 2xl:text-sm">
                    <div className="xl:grid grid-cols-12 items-center space-y-8 xl:space-y-0">
                        <div className="space-y-8 col-span-2 2xl:col-span-2 xl:col-span-2">
                            <h2 className="">Pool Closes</h2>
                            <div className="">
                                <img src="/img/no.png" alt="" className="m-auto" />

                            </div>
                            {/* <p className="">00:00:00</p> */}
                        </div>
                        <div className="">
                            <div className="bg-[#8D8D8D4D] m-auto  w-[1px] h-[135px]"></div>    
                        </div>
                        <div className="space-y-8 col-span-2">
                            <h2 className="">Pool Rewards
                                Distribution in:</h2>
                            <div className="">
                                <img src="/img/no.png" alt="" className="m-auto" />

                            </div>
                            {/* <p className="">00:00:00</p> */}
                        </div>
                        <div className="">
                            <div className="bg-[#8D8D8D4D]  m-auto w-[1px] h-[135px]"></div>    
                        </div>
                        <div className="space-y-8 col-span-4 2xl:col-span-3 xl:col-span-3">
                            <h2 className="">ETH Closing  Price</h2>
                            <div className="space-y-2">
                                <input type="text" className="opacity-[36%] bg-transparent outline-none border-none border-b border " placeholder='Enter ETH Closing Price' />

                                <button className="bg-[#92d940dd] 2xl:px-4 px-3 py-2 2xl:py-1 xl:py-0 text-[10px] rounded-full"><p className="2xl:text-[10px] xl:text-[8px] xl:py-1 xl:font-medium">Submit To Join Pool</p></button>

                            </div>

                        </div>
                        <div className="">
                            <div className="bg-[#8D8D8D4D] m-auto  w-[1px] h-[135px]"></div>    
                        </div>
                        <div className="space-y-8 col-span-1 2xl:col-span-2 xl:col-span-2">
                            <h2 className="">Pool History</h2>
                            <div className="leading-tight space-y-2 ">
                                <p className="opacity-[36%]">Current Pool
                                </p>
                                <p className="opacity-[36%]">Previous Pool
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:flex space-y-4 xl:space-y-0 items-center w-[60%] m-auto gap-3 my-8">
                    <button className="bg-[#F95454] rounded-full w-full  py-4 xl:py-1 2xl:py-4">Withdraw</button>
                    <button className="bg-[#92D940] rounded-full w-full  py-4 xl:py-1 2xl:py-4">Deposit</button>
                </div>
            </div>
        </div>
    );
};

export default EthPoolCard;