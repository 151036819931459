import React, { Fragment, createContext, useEffect, useState } from "react";
import AdminNav from "../Components/AdminNav";

import NFTListModal from "../Components/NftListModal";
import { useQuery, gql } from "@apollo/client";
import {
  useAccount,
  useContractRead,
  useContractReads,
  useWaitForTransaction,
} from "wagmi";
import nftContractAbi from "../Contracts/nft.json";
import toast, { Toaster } from "react-hot-toast";
import Web3 from "web3";
import { formatEther } from "viem";
export const GenesisStakingContext = createContext(null);

const GenesisStakingtaking = () => {
  const MyBackgroundImage = "/img/bg1.png";
  const account = useAccount();
  const [showModal, setShowModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [query, setQuery] = useState(
    gql`
      query NFTList($address: ID!) {
        user(id: $address) {
          id
          nfts(where: { staked: false }, orderBy: tokenId) {
            tokenId
          }
        }
      }
    `
  );
  const [variables, setVariables] = useState({
    address: account.isConnected ? account.address.toLowerCase() : "",
  });
  const [buttonText, setButtonText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [stakedNftArray, setStakedNftArray] = useState([]);
  const [totalBibkAvailable, setTotalBibkAvailable] = useState(0);
  const [earnedBibk, setEarnedBibk] = useState(0);
  const [transactionHash, setTransactionHash] = useState("");
  const [dailyRewards, setDailyRewards] = useState("0");
  const [totalBibkEarned, setTotalBibkEarned] = useState("0");

  const FETCHSTAKEDNFT_QUERY = gql`
    query StakedNFTList($userAddress: ID!) {
      user(id: $userAddress) {
        nfts(where: { staked: true }, orderBy: tokenId) {
          tokenId
        }
      }
    }
  `;

  const fetchStakedNftQuery = useQuery(FETCHSTAKEDNFT_QUERY, {
    variables: {
      userAddress: account.isConnected ? account.address.toLowerCase() : "",
    },
  });

  const getPreparedData = (
    userAddress,
    address,
    abi,
    functionName,
    array = [],
    key = null
  ) => {
    let args = [];
    for (let i = 0; i < array.length; i++) {
      const preparedData = {
        address: address,
        abi: abi,
        functionName: functionName,
        args: [array[i][key]],
        account: userAddress,
      };
      args.push(preparedData);
    }
    return args;
  };

  const tokenUrisFunc = useContractReads({
    contracts: getPreparedData(
      account.address,
      process.env.REACT_APP_NFT_CONTRACT,
      nftContractAbi,
      "tokenURI",
      stakedNftArray,
      "tokenId"
    ),
  });

  useEffect(() => {
    const fetchData = async (data) => {
      const promises = data.map(async (item) => {
        if (item.status !== "failure") {
          const response = await fetch(item.result);
          const responseJson = await response.json();
          return {
            availableBibk: parseInt(responseJson.attributes[0].value),
          };
        }
      });

      const availableBibkArray = await Promise.all(promises);
      const sum = availableBibkArray.reduce(
        (total, item) => total + item.availableBibk,
        0
      );
      setTotalBibkAvailable(sum);
    };

    if (tokenUrisFunc.isFetched) {
      fetchData(tokenUrisFunc.data);
    }
  }, [tokenUrisFunc]);

  const earnedRewardsFunc = useContractReads({
    contracts: getPreparedData(
      account.address,
      process.env.REACT_APP_NFT_CONTRACT,
      nftContractAbi,
      "getEarnedRewards",
      stakedNftArray,
      "tokenId"
    ),
  });

  useEffect(() => {
    if (!earnedRewardsFunc.isLoading && earnedRewardsFunc.data) {
      console.log("earnedRewardsFunc", earnedRewardsFunc.data);
    }
  }, [earnedRewardsFunc]);

  const transactionConfirmHook = useWaitForTransaction({
    hash: transactionHash,
  });

  useEffect(() => {
    if (!fetchStakedNftQuery.loading && fetchStakedNftQuery.data.user) {
      setStakedNftArray(fetchStakedNftQuery.data.user.nfts);
    } else {
      setStakedNftArray([]);
    }
  }, [fetchStakedNftQuery]);

  useEffect(() => {
    if (transactionConfirmHook.isSuccess && !transactionConfirmHook.isLoading) {
      toast.success(toastMessage);
      fetchStakedNftQuery.refetch();
      setShowModal(false);

    } else if (transactionConfirmHook.error) {
      toast.error(`Error: ${transactionConfirmHook.error.message}`);
    }
  }, [transactionConfirmHook.isLoading]);

  const dailyRewardsFunc = useContractRead({
    address: process.env.REACT_APP_NFT_CONTRACT,
    abi: nftContractAbi,
    functionName: "dailyRewards",
  });

  const totalBibkEarnedFunc = useContractRead({
    address: process.env.REACT_APP_NFT_CONTRACT,
    abi: nftContractAbi,
    functionName: "totalBibkEarned",
  });

  useEffect(() => {
    if (dailyRewardsFunc.isSuccess) {
      setDailyRewards(formatEther(dailyRewardsFunc.data));
    }
  }, [dailyRewardsFunc]);

  useEffect(() => {
    if (totalBibkEarnedFunc.isFetched && totalBibkEarnedFunc.data) {
      setTotalBibkEarned(
        parseFloat(formatEther(totalBibkEarnedFunc.data)).toFixed(2)
      );
    }
  }, [totalBibkEarnedFunc]);
  // useEffect(() => {
  //   if(earnedRewardsFunc.isFetched) {
  //     setEarnedBibk(parseFloat(Web3.utils.fromWei(earnedRewardsFunc.data, "ether")).toFixed(4));
  //   }
  // }, [earnedRewardsFunc.isFetched])

  // const MyBackgroundImage = "/img/group1.png";
  return (
    <GenesisStakingContext.Provider
      value={{ dailyRewards, buttonText, setTransactionHash, transactionConfirmHook }}
    >
      <>
        <AdminNav />
        <div className="py-10 xl:py-10 2xl:py-20 m-auto w-[90%] lg:flex  flex-col justify-center">
          <div className="_0shine space-y-5 text-justify rounded-xl p-5 xl:p-12 xl:w-[85%] 2xl:w-[80%] m-auto">
            <div className="xl:flex items-cente justify-between space-y-4 xl:space-y-0 ">
              <div className="space-y-2 xl:space-y-1">
                <h1 className="md:text-2xl text-xl xl:text-2xl 2xl:text-4xl font-bold">
                  BinaryBlock Genesis Staking
                </h1>
                <p className="text-[#92D940] xl:text-sm 2xl-text-md">
                  Earn BIBK from BinaryBlock genesis asset.
                </p>
              </div>
              <div className="">
                <button className="rounded-full text-black  xl:text-sm bg-[#92D940] hover:bg-[#71a832]  hover:cursor-pointer py-2 px-3">
                  Balances
                </button>
              </div>
            </div>
            <div className="xl:w-[80%] m-auto py-5 xl:py-0 text-center ">
              <div className="xl:flex justify-between items-center space-y-6 xl:space-y-0">
                <div className="">
                  <div className="text-white font-bol space-y-2">
                    <p className=" text-white">
                      Earn {dailyRewards} BIBK Daily
                    </p>
                    <p className=" text-[#92D940]">
                      Total BIBK Earned: {totalBibkEarned}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col justify-center text-center items-center">
                  <div
                    className="xl:w-[400px] h-[400px] w-[310px] md:w-[400px]"
                    style={{
                      backgroundImage: `url(${MyBackgroundImage})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="flex flex-col h-[400px] md:h-[400px] xl:h-[400px] space-y-2 justify-center ">
                      <div className=" leading-tight text-center">
                        <h1 className="">
                          <span className="text-5xl no text-[#92D940] no">
                            TNL:
                          </span>
                        </h1>
                        <p className="text-sm">Total NFT Locked</p>
                      </div>
                      <div className="text-center">
                        <h1 className="text-5xl font-extrabold no">
                          {stakedNftArray.length}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="space-y-5">
                  <div className=" space-y-2 xl:text-sm">
                    <p className=" text-white">
                      Number of NFTs staked: {stakedNftArray.length}
                    </p>
                    <button
                      onClick={() => {
                        setQuery(FETCHSTAKEDNFT_QUERY);
                        setVariables({
                          userAddress: account.isConnected
                            ? account.address.toLowerCase()
                            : "",
                        });
                        setToastMessage("NFT unstaked successfully");
                        setButtonText("Unstake");
                        setModalTitle("Staked NFTs");
                        setShowModal(true);
                      }}
                      className="bg-white text-black text-sm hover:bg-blue-gray-50 rounded-full px-4 py-2"
                    >
                      Unstake NFT
                    </button>
                  </div>
                  <div className="text-white ">
                    <p className="text-white">BIBK Earned/Available:</p>
                    <p className=""> 0.0000/{totalBibkAvailable}</p>
                  </div>
                </div>
              </div>
            </div>
            <Fragment>
              <div className="flex flex-col items-center py-10 xl:py-0 text-center m-auto">
                <button
                  onClick={() => {
                    setQuery(
                      gql`
                        query NFTList($address: ID!) {
                          user(id: $address) {
                            nfts(where: { staked: false }, orderBy: tokenId) {
                              tokenId
                            }
                          }
                        }
                      `
                    );
                    setVariables({
                      address: account.isConnected
                        ? account.address.toLowerCase()
                        : "",
                    });
                    setToastMessage("NFT staked successfully");
                    setButtonText("Stake");
                    setModalTitle("NFTs available to stake");
                    setShowModal(true);
                  }}
                  className="bg-[#92D940] hover:bg-[#71a832] xl:w-[30%] px-4 py-4 rounded-full text-black font-medium text-sm "
                >
                  Stake BinaryBlock Genesis
                </button>
              </div>
              <NFTListModal
                visible={showModal}
                query={query}
                variables={variables}
                title={modalTitle}
                transactionConfirmHook={transactionConfirmHook}
                onClose={() => {
                  setShowModal(false);
                }}
              />
            </Fragment>
          </div>
        </div>
        <Toaster
          toastOptions={{
            style: {
              maxWidth: "none",
            },
          }}
        />
      </>
    </GenesisStakingContext.Provider>
  );
};

export default GenesisStakingtaking;
