import React, { useState } from 'react';
import AdminNav from '../Components/AdminNav';
import CurrentPositionPredictionPool from '../Components/CurrentPositionPredictionPool';
import EthPosition from '../Components/EthPosition';

const PredictionPool = () => {
    const [crypto, setCrpto] = useState(true)
    const [eth, setEth] = useState(false)

    function handleCrpto() {
        setEth(false)
        setCrpto(true)
    }

    function handleEth() {
        setEth(true)
        setCrpto(false)
    }

    return (
        <div>
            <AdminNav />
            <div className="m-auto w-[85%] py-10 xl:py-10 2xl:py-20">
                <div className="xl:grid grid-cols-5 2xl:w-[85%] xl:w-[90%] m-auto space-y-10 xl:space-y-0 gap-20 text-white ">
                    <div className="space-y-3 col-span-2 xl:text-sm 2xl:text-md">
                        <h1 className="text-[#92D940] text-[24px] font-bold">BIBK Price Prediction Pool</h1>
                        <div className="space-y-2">
                            <p className="text-sm xl:leading-relaxed">Predicting the closing price of a crypto asset can be an engaging activity for enthusiasts. In this scenario, participants have the opportunity to win substantial rewards:</p>
                            <p className=""><span className='text-[#92D940]'>Pool Winner: </span> The participant who accurately predicts the closing price of the crypto asset stands to win an impressive 1000% of their token in pool.</p>
                            <p className=""><span className='text-[#92D940]'>Pool Runner-Up: </span> Even if a participant doesn't secure the top spot, they still receive incentives as a runner-up, which adds an extra layer of motivation to participate.</p>
                        </div>
                        <div className="space-y-10 2xl:py-2 xl:py-1 py-3">

                            <div className="bg-[#42424270] w-fit flex gap-3 pl-2 ">
                                <button className={!crypto ? " " : "bg-[#92D940] rounded-xl px-5 py-2"} onClick={handleCrpto}>BTC/USD Pool</button>
                                <button className={!eth ? "px-5" : "bg-[#92D940] rounded-xl px-5 py-2"} onClick={handleEth} >ETH/USD Pool</button>
                            </div>
                            <div className="">
                                <img src="/img/chart.png" alt="" className="w-full" />
                            </div>
                        </div>
                        <div className="flex flex-col xl:justify-end xl:items-end space-y-2 text-md">
                            <p className="">Interest Rate:  <span className='text-[#92D940]'> 21% APY</span></p>
                            <p className="">Available BIBK:  <span className='text-[#92D940]'> 600,000 BIBK</span></p>
                            <p className="">Borrowed BIBK:  <span className='text-[#92D940]'> 300,000 BIBK</span></p>
                            <p className="">Total BIBK Locked in the lending pool:  <span className='text-[#92D940]'> 900,000 BIBK</span></p>
                            <p className="">Total BIBK earn in the lending pool:   <span className='text-[#92D940]'> 369,999 BIBK</span></p>

                        </div>
                        <div className="flex items-center gap-3 text-sm py-4">
                            <button className="bg-[#42424270]  px-3 py-3 w-full rounded-xl ">Your BIBK in Lending Pool: <span className='text-[#92D940]'> 0000 BIBK</span></button>
                            <button className="bg-[#42424270] px-3 py-3 w-full rounded-xl"> Lending Pool Earnings:<span className='text-[#92D940]'> 0000 BIBK</span></button>

                        </div>
                    </div>
                    <div className="col-span-3 ">
                        {crypto && <CurrentPositionPredictionPool />}
                        {eth && <EthPosition />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PredictionPool;