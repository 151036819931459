import React from 'react';
import PoolCard from '../PoolCard';

const ElevenBibk = () => {
    return (
        <div>
     <div className="xl:grid grid-cols-3 gap-5 py-12 2xl:py-5 xl:py-2 text-center items-center text-sm">
                <div className="list-none space-y-5 ">
                    <li className=""> Maximum Entries: </li>
                    <li className=""> Current Entries: </li>
                    <li className="">Total BIBK in Pool: </li>
                    <li className=""> Pool Winner(s): </li>
                    <li className=""> Pool Incentive per entry: </li>
                </div>
                <div className="">

                    <div className="list-none text-[#92D940] space-y-5 ">
                        <li className="">100K </li>
                        <li className="">0</li>
                        <li className="">0,000,000 BIBK </li>
                        <li className="">110 BIBK </li>
                        <li className=""> 21% APY Each </li>
                    </div>
                </div>

                <div className="space-y-2 ">
                    <div className="list-none ">
                        <li className=""> 30,001.0000 </li>
                        <li className="">30,000.9009</li>
                        <li className=""> 30,000.0000 </li>
                    </div>
                    <div className="">
                        <h2 className="">Mark Price</h2>
                        <p className="text-[#92D940]"> 00,000.0000</p>
                    </div>
                    <div className="list-none ">
                        <li className="">   29,999.9988 </li>
                        <li className=""> 29,900.3333</li>
                        <li className="">   29,999.0000</li>
                    </div>

                </div>
            </div>
            <div className="">
                {/* <PoolCard/> */}
            </div>
        </div>
    );
};

export default ElevenBibk;